
/* eslint-disable */
import { vm } from '../main'
import { Loading } from "element-ui";

function setCookie(name, value, time) {
  var exp = new Date();
  time = time === "long" ? 99999999 : time
  exp.setTime(exp.getTime() + 60000 * time);//过期时间 2分钟
  document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  console.log(name + "=" + escape(value) + ";expires=" + exp.toGMTString());
}
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}

export function my_throttle(func, wait) {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args)
      }, wait)
    }

  }
}

export function getCurrentDate(format) {
  var now = new Date();
  var year = now.getFullYear(); //得到年份
  var month = now.getMonth();//得到月份
  var date = now.getDate();//得到日期
  var day = now.getDay();//得到周几
  var hour = now.getHours();//得到小时
  var minu = now.getMinutes();//得到分钟
  var sec = now.getSeconds();//得到秒
  month = month + 1;
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;
  if (hour < 10) hour = "0" + hour;
  if (minu < 10) minu = "0" + minu;
  if (sec < 10) sec = "0" + sec;
  var time = "";
  //精确到天
  if (format == "date") {
    time = year + "-" + month + "-" + date;
  }
  //精确到分
  else if (format == "hour") {
    time = year + "-" + month + "-" + date + " " + hour;
  } else if (format == "minute") {
    time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
  }
  return time;
}

let time = {
  begin: "2020/8/31 21:00:00",
  choice: "2020/8/31 21:30:00",
  fillBlank: "2020/8/31 21:50:00",
  programming: "2020/8/31 21:00:00"
}


export function countDown(type) {
  let nowTime = new Date();
  let str = time[type];
  let endTime = new Date(str);

  var lefttime = endTime.getTime() - nowTime.getTime() //距离结束时间的毫秒数

  if (lefttime < 0) {
    return "00:00:00"
  }

  var lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24),//计算小时数
    leftm = Math.floor((lefttime / (1000 * 60)) % 60),//计算分钟数
    lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
  return perFix(lefth) + ":" + perFix(leftm) + ":" + perFix(lefts); //返回倒计时的字符串
}


function perFix(num) {
  return num >= 10 ? num : "0" + num;
}

export function getUserMedia(constraints, success, error) {
  if (navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(success)
      .catch(error);
  } else if (navigator.webkitGetUserMedia) {
    navigator.webkitGetUserMedia(constraints, success, error);
  } else if (navigator.mozGetUserMedia) {
    navigator.mozGetUserMedia(constraints, success, error);
  } else if (navigator.getUserMedia) {
    navigator.getUserMedia(constraints, success, error);
  }
}


export function returnQuestion(category) {
  let examID = vm.$localStorage.get("examID")
  console.log(examID)
  return new Promise((resolve, reject) => {
    console.log(category)
    vm.$http
      .post("/exam", {
        mode: "getQuestion",
        token: vm.$localStorage.get("token"),
        examID: examID,
        choiceMode: category || vm.$localStorage.get("login_status_category")
      })
      .then((res) => {
        console.log('题目', res);

        // 科创赛抽取题目
        // let my_subject = vm.$localStorage.get("my_subject")
        // if (my_subject) {
        //   res.data[examID].programming = res.data[examID].programming.slice(my_subject - 1, my_subject)
        // }

        resolve(res.data[examID])
      }).catch(err => {
        vm.$alert(err, "失败", {
          confirmButtonText: "确定",
          callback: (action) => {
          },
        });
      })
  })
}

// export function submitAll(type) {
//   let loadingInstance = Loading.service();
//   let examID = vm.$localStorage.get("examID")

//   return new Promise((resolve, reject) => {
//     console.log(type)
//     vm.$http
//       .post("/exam", {
//         mode: "submit",
//         username: vm.$localStorage.get("login_status_username"),
//         password: vm.$localStorage.get("login_status_token"),
//         category: vm.$localStorage.get("login_status_category"),
//         data: JSON.stringify(vm.$localStorage.get(type)),
//         questionMode: type,
//         examID: examID,
//       })
//       .then((res) => {
//         loadingInstance.close();

//         vm.$message({
//           message: "提交成功",
//           center: true,
//           type: "success",
//           showClose: true,
//           duration: 1500,
//         });
//         //跟新进度假的，这里都是从网络获取
//         console.log(res);
//         // let myInfo = res.data.exam;
//         // this.$localStorage.set("steam_exam_status", myInfo);
//         vm.$router.push({ name: "Notice", params: { id: "3" } }); //跳转页面
//         // this.$router.push("/question/fillBlank");
//       })
//       .catch((err) => {
//         loadingInstance.close();

//         console.log(err);
//         vm.$alert(err, "提交失败", {
//           confirmButtonText: "确定",
//           type: "warning",
//         });
//       });
//   })
// }


//kcs选题的提交
export function submitAll({ type, form }) {
  let loadingInstance = Loading.service();
  let examID = vm.$localStorage.get("examID")
  console.log('form', form)

  if (form) {
    //获取题目数据，要把examid 和题型一起加上
    let data = vm.$localStorage.get(type)
    data[form.questionID].answer.push(form)
    let programming = {
      quesIndex: form.questionID,
      answer: data//把form压进去
    };

    console.log(programming)

    vm.$store.commit("updateRecord", {
      choice: programming,
      type: "programming",
    });
  }


  return new Promise((resolve, reject) => {
    let data = vm.$localStorage.get(type)
    vm.$http
      .post("/exam", {
        mode: "submit",
        username: vm.$localStorage.get("login_status_username"),
        password: vm.$localStorage.get("login_status_token"),
        category: vm.$localStorage.get("login_status_category"),
        data: data,
        questionMode: type,
        examID: examID,
      })
      .then((res) => {
        loadingInstance.close();

        vm.$message({
          message: "提交成功",
          center: true,
          type: "success",
          showClose: true,
          duration: 1500,
        });
        //跟新进度假的，这里都是从网络获取
        console.log(res);
        // let myInfo = res.data.exam;
        // this.$localStorage.set("steam_exam_status", myInfo);
        vm.$router.push({ name: "Notice", params: { id: "3" } }); //跳转页面
        // this.$router.push("/question/fillBlank");
      })
      .catch((err) => {
        loadingInstance.close();

        console.log(err);
        vm.$alert(err, "提交失败", {
          confirmButtonText: "确定",
          type: "warning",
        });
      });
  })
}



export async function getTime(type) {
  let loadingInstance = Loading.service();
  let examID = vm.$localStorage.get("examID")

  return new Promise((resolve, reject) => {
    console.log(type)
    vm.$http
      .post("/utils", {
        mode: "returnDate",
        examID: examID
      })
      .then((res) => {

        loadingInstance.close();
        //跟新进度假的，这里都是从网络获取
        resolve(res);
      })
      .catch((err) => {
        loadingInstance.close();

        console.log(err);
        vm.$alert(err, "网络连接失败请重试", {
          confirmButtonText: "确定",
          type: "warning",
        });
      });
  })
}

export function DetectBrowser() {
  const userAgent = window.navigator.userAgent
  const isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Edge') === -1 && userAgent.indexOf('MetaSr') === -1;

  console.log('dect', isChrome)
 //调用云端检测
}




export function noF12() {
  var threshold = 100;
  let timer1 = setInterval(async function () {
    //当浏览器窗口宽度或高度与页面的宽度和高度的差值大于100的时候触发页面刷新


    if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
      // 刷新页面；

      var db = vm.$serverLess.database();
      const _ = db.command
      await db
        .collection("userList")
        .where({ username: vm.$localStorage.get("login_status_username") })
        .update({
          console: _.inc(1)
        });

      window.location.reload();
      alert('您的行为已被记录，请关闭控制台重新输入地址进入网站');
    }
  })
}


let handleResize = throttle(function () {
  var db = vm.$serverLess.database();
  const _ = db.command
  db
    .collection("userList")
    .where({ username: vm.$localStorage.get("login_status_username")+'' })
    .update({
      console: _.inc(1)
    });
}, 3000);


function throttle(fn, delay) {
  // 定义一个标志位，记录上一次执行的时间

  let prev = Date.now();
  // 返回一个包装后的函数
  return function () {
    // 获取当前时间
    let now = Date.now();
    // 获取函数的执行上下文和参数
    let context = this;
    let args = arguments;
    // 如果当前时间减去上一次执行时间大于等于延迟时间，就执行函数
    if (now - prev >= delay) {
      fn.apply(context, args);
      // 更新上一次执行时间为当前时间
      prev = Date.now();
    }
  }
}

let scrollAnimation = {
    creat(document, parmsList) {
      var elementList = []
      for (let value of parmsList) {
        if (value.type == "tag") elementList.push({ type: value.type, elem: document.getElementsByTagName(value.name), class: value.class });
        else if (value.type == "class") elementList.push({ type: value.type, elem: document.getElementsByClassName(value.name), class: value.class })
        else elementList.push(document.getElementById({ type: value.type, elem: document.getElementsById(value.name), class: value.class }))
      }
    }
  }



//async await generate语法糖


// //数组
// var arr = [1, 2, 3, 4, 5, 6]
// var [a, b, ...rest] = arr
// console.log(rest);//[ 3, 4, 5, 6 ]


// //对象
// var { a, b, ...rest } = { a: 2, b: 3, c: 3, d: 3, e: 3 };
// console.log(rest);//{ c: 3, d: 3, e: 3 }


// //函数
// function restFunc(a, ...arg) {
//   //将参数收敛
// }

// console.log(spread(...[2, 2, 4]))//8

//   `aaaaaa ${aa}
// `

// var a = new Set([1, 2, 3, 4, 4])
// console.log(set)//Set { 1, 2, 3, 4 }
// console.log([...set])//[ 1, 2, 3, 4]
// set.add(5);
// console.log([...set])//[ 1, 2, 3, 4, 5 ]


// const b = new Map([["a", 1]])//如果是new Map(["a", 1])会出现Iterator value a is not an entry object
// const c={a:"aaa"}
// b.set(c,"cccc")
// console.log(b)//Map { 'a' => 1, { a: 'aaa' } => 'cccc' }
// console.log(b.size)//2


// var b={a:"111",b:"aaaa"}
// console.log(b.length)